<template>
    <div class="CreatePromotionLaunch">
        <el-card shadow="never" style="margin-top: 8px">
            <div style="display: flex; justify-content: space-between; padding-right: 20px">
                <el-steps
                    :active="2"
                    finish-status="success"
                    simple
                    style="margin-left: 14px; width: 300px; color: #1ff680; font-size: 14px"
                >
                    <el-step title="活动设置">
                        <slot></slot>
                    </el-step>
                    <el-step title="投放设置" icon=""></el-step>
                </el-steps>
                <el-form>
                    <el-button
                        size="medium"
                        type="primary"
                        plain
                        @click="onGoBack"
                        v-if="hasPrivilege('menu.promotion.npromotion.edit')"
                        >上一步
                    </el-button>
                    <el-button
                        size="medium"
                        type="primary"
                        @click="onSave"
                        v-if="
                            hasPrivilege('menu.promotion.npromotion.edit') ||
                            hasPrivilege('menu.promotion.npromotion.create')
                        "
                        >确认投放
                    </el-button>
                </el-form>
            </div>
        </el-card>
        <el-card style="margin-top: 8px" shadow="never">
            <el-form size="medium">
                <el-form-item label="活动类型">
                    <span v-if="form.rule == 4">单品件数折扣特价 </span>
                    <span v-else-if="form.rule == 5">任选一口价</span>
                    <span v-else-if="form.rule == 6">单品满赠</span>
                    <span v-else-if="form.rule == 7">固定一口价</span>
                    <span v-else-if="form.rule == 8">任选件数折扣</span>
                </el-form-item>
                <el-form-item label="投放日期">
                    <efn-date-range
                        v-model="meta.launchTime"
                        :show-past="false"
                        :show-future="true"
                        ref="range"
                    ></efn-date-range>
                </el-form-item>
                <el-form-item label="投放星期">
                    <el-checkbox-group v-model="meta.weekdays">
                        <el-checkbox :label="1">周一</el-checkbox>
                        <el-checkbox :label="2">周二</el-checkbox>
                        <el-checkbox :label="3">周三</el-checkbox>
                        <el-checkbox :label="4">周四</el-checkbox>
                        <el-checkbox :label="5">周五</el-checkbox>
                        <el-checkbox :label="6">周六</el-checkbox>
                        <el-checkbox :label="7">周日</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="活动人群">
                    <el-radio-group v-model="form.memberScopeFlag">
                        <el-radio-button :label="2">全体</el-radio-button>
                        <el-radio-button :label="1">仅会员</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="单笔限购" v-if="form.rule == 4 || form.rule == 6">
                    <el-radio-group v-model="meta.enableBuyLimit">
                        <el-radio-button :label="false">关闭</el-radio-button>
                        <el-radio-button :label="true">开启</el-radio-button>
                    </el-radio-group>
                    <span style="margin-left: 20px" class="clr-textGray">每个人每笔交易最多可参与</span>
                    <ef-price-input
                        :precision="0"
                        :min="1"
                        :max="9999"
                        style="width: 60px"
                        v-model="meta.buyLimit"
                        :disabled="!meta.enableBuyLimit"
                    ></ef-price-input>
                    <span class="clr-textGray">次本促销活动</span>
                </el-form-item>
                <el-form-item label="价格策略" v-show="form.rule == 4 || form.rule == 6">
                    <efn-radio-groups :btns="meta.btns" v-model="form.memberPriceType"></efn-radio-groups>
                </el-form-item>
            </el-form>
            <h3>投放门店</h3>
            <hr class="efHr" style="background-color: #ccc" />
            <el-form>
                <el-form-item>
                    <el-button type="text" @click="onLaunchAll">全部投放</el-button>
                    <el-button type="text" class="clr-pink" @click="onUnLaunchAll">全部取消投放</el-button>
                </el-form-item>
            </el-form>
            <efn-table :data="form.depts">
                <el-table-column type="index" label="No"></el-table-column>
                <el-table-column label="门店名称" width="280px" prop="deptName"></el-table-column>
                <el-table-column label="投放" width="120px">
                    <template slot-scope="scope">
                        <el-switch
                            :disabled="ifLaunchDiable(scope.row.deptCode)"
                            active-text="是"
                            inactive-text="否"
                            v-model="scope.row.state"
                            :active-value="1"
                            :inactive-value="0"
                        ></el-switch>
                    </template>
                </el-table-column>
            </efn-table>
        </el-card>
    </div>
</template>

<script>
import EfnTable from 'components/newStyle/EfnTable';
import EfnDateRange from 'components/newStyle/EfnDateRange';
import EfPriceInput from 'components/EfPriceInput';
import EfnRadioGroups from 'components/newStyle/EfnRadioGroups';

export default {
    name: 'CreatePromotionLaunch',
    components: { EfnRadioGroups, EfPriceInput, EfnDateRange, EfnTable },
    props: {
        promotionCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            form: {
                deptGroupCode: '',
                promotionCode: '',
                rule: null,
                startDate: '',
                endDate: '',
                weekdays: '1,2,3,4,5,6,7',
                memberScopeFlag: 2,
                buyLimit: null,
                memberPriceType: 1, //1 固定活动价优先,2与会员价相比,低价优先
                depts: [],
            },
            meta: {
                buyLimit: null,
                enableBuyLimit: false,
                launchTime: ['', ''],
                weekdays: [1, 2, 3, 4, 5, 6, 7],
                btns: [],
                depts: [],
            },
            url: {
                operate: '/npromotion/launch/',
            },
        };
    },
    watch: {
        meta: {
            handler: function (val) {
                if (val.enableBuyLimit) {
                    this.meta.buyLimit = this.meta.buyLimit ? this.meta.buyLimit : 1;
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.queryLaunch();
        (async () => {
            this.meta.depts = ((await this.$efApi.deptApi.managedAllDepts()) || []).map((a) => a.code);
        })();
    },
    methods: {
        ifLaunchDiable(code) {
            return this.meta.depts.indexOf(code) < 0;
        },
        tips() {
            if (this.form.rule == 4) {
                return [
                    {
                        text: '活动价优先',
                        label: 1,
                        tips:
                            '满足每条活动的商品计价时固定使用活动价格，忽略会员价优惠，如会员价9折，设置满1件95折，满两件85折，如果会员购买3件，则前2件85折，第3件95折',
                    },
                    {
                        text: '低价优先',
                        label: 2,
                        tips:
                            '满足每条活动的商品计价时活动价与会员价比较，优惠力度大的优先，如会员价9折，设置满1件95折，满两件85折，如果会员购买3件，则前2件85折，第3件9折',
                    },
                ];
            }
            if (this.form.rule == 6) {
                return [
                    {
                        text: '仅享赠品',
                        label: 1,
                        tips: '满足每条活动时只享受赠品，商品原价，忽略会员价优惠',
                    },
                    {
                        text: '享会员价及赠品',
                        label: 2,
                        tips: '满足每条活动时享受赠品及商品会员价优惠',
                    },
                ];
            }
            return [];
        },
        onGoBack() {
            if (this.form.rule == 4) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.specialOffer',
                    params: { editPromotionCode: this.form.promotionCode },
                });
            } else if (this.form.rule == 5) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.buyoutPrice',
                    params: { editPromotionCode: this.form.promotionCode },
                });
            } else if (this.form.rule == 6) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.giveaway',
                    params: { editPromotionCode: this.form.promotionCode },
                });
            } else if (this.form.rule == 7) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.fixBuyOut',
                    params: { editPromotionCode: this.form.promotionCode },
                });
            } else if (this.form.rule == 8) {
                this.$router.push({
                    name: 'menu.promotion.npromotion.create.fixDiscount',
                    params: { editPromotionCode: this.form.promotionCode },
                });
            }
            this.$store.commit('delTabRoute', 'menu.promotion.npromotion.create.launch');
        },
        async onSave() {
            const _this = this;
            const val = this.meta;
            if (val.launchTime && val.launchTime[0]) {
                this.form.startDate = val.launchTime[0];
                this.form.endDate = val.launchTime[1];
                const startTime = _this.$refs.range.$children[0].parsedValue[0].getTime();
                if (startTime < Date.now()) {
                    const result = await this.$confirm(
                        '投放时间小于等于当前时间，投放后的活动将不可编辑, 是否继续?',
                        '提示',
                        {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }
                    )
                        .then(() => {
                            return true;
                        })
                        .catch(() => {
                            return false;
                        });
                    if (!result) {
                        return;
                    }
                }
            } else {
                this.form.startDate = '';
                this.form.endDate = '';
            }
            this.loadingStart();
            this.form.weekdays = (val.weekdays || []).join(',');
            this.form.buyLimit = val.enableBuyLimit ? this.meta.buyLimit : 0;
            const rst = await this.$http.post(this.url.operate + this.promotionCode, this.form).catch(() => {
                _this.loadingStop();
            });
            this.loadingStop();
            if (rst.data.status != 200) {
                this.$message.error(rst.data.msg);
            } else {
                this.$message.success('投放成功');
                _this.$router.push({
                    name: 'menu.promotion.npromotion',
                    params: { promotionCode: _this.form.code },
                });
                this.$store.commit('delTabRoute', 'menu.promotion.npromotion.create.launch');
            }
        },
        async queryLaunch() {
            const rst = await this.$http.get(this.url.operate + this.promotionCode);
            Object.assign(this.form, rst.data.data);
            this.meta.weekdays = this.form.weekdays.split(',').map((m) => Number.parseInt(m));
            this.meta.launchTime = [this.form.startDate, this.form.endDate];
            this.meta.enableBuyLimit = (this.form.buyLimit || 0) > 0;
            this.meta.buyLimit = this.form.buyLimit;
            this.meta.btns = this.tips();
        },

        onUnLaunchAll() {
            this.form.depts = this.form.depts.map((a) => {
                if (this.ifLaunchDiable(a.deptCode)) {
                    return a;
                }
                a.state = 0;
                return a;
            });
        },
        onLaunchAll() {
            this.form.depts = this.form.depts.map((a) => {
                if (this.ifLaunchDiable(a.deptCode)) {
                    return a;
                }
                a.state = 1;
                return a;
            });
        },
    },
};
</script>

<style>
.CreatePromotionLaunch .wrap {
    width: 100px;
}
</style>
